import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  InputBase,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  AccountCircle,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Logo.png";
import "./Header.scss";

const Header = ({ onMenuClick, handleMenuClick }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // For dropdown menu

  // Check local storage for login status
  useEffect(() => {
    const userLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";
    setIsUserLoggedIn(userLoggedIn);
  }, []);

  // Handle click for logout icon
  const handleLogoutIconClick = (event) => {
    setAnchorEl(event.currentTarget); // Open dropdown menu
  };

  // Handle logout option
  const handleLogout = () => {
    localStorage.clear(); // Clear all local storage values
    setIsUserLoggedIn(false); // Update the state
    setAnchorEl(null); // Close dropdown menu
    navigate("/login"); // Optionally navigate to login page
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Close dropdown menu
  };

  const gotoHome = () => {
    navigate("/");
  }

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#18AEC5", paddingX: isMobile ? 2 : 50 }}
    >
      <Toolbar
        className="header"
        sx={{
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobile ? "100%" : "840px",
          backgroundColor: "#18AEC5",
        }}
      >
        {/* Left Section - Logo and Menu */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%", // Full width on mobile
            justifyContent: "space-between", // Space between elements
            flexDirection: "row", // Align items in a row
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "start" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onMenuClick}
              sx={{
                ml: isMobile ? -2 : 2, // Ensure icon is aligned to the left on mobile view
              }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              className={`logo ${isMobile ? "mobile-logo" : ""}`}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={logo}
                alt="Logo"
                style={{ height: isMobile ? "35px" : "40px" }}
                onClick={gotoHome}
              />
            </Box>
          </Box>

          {/* Right Section - Login/Account Buttons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end", // Align buttons to the right
              ml: isMobile ? 0 : 2,
            }}
          >
            {/* Search Bar */}
            {!isMobile && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgba(255,255,255,0.15)",
                  borderRadius: 1,
                  p: 0.2,
                  width: "auto", // Search bar width on desktop
                  mb: 0,
                  flexGrow: 1, // Allow it to grow
                }}
              >
                <InputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  sx={{
                    color: "inherit",
                    ml: 1,
                    fontSize: "0.875rem",
                    flexGrow: 1,
                    width: "100%", // Ensure InputBase fills the width
                  }} // Reduced font size
                />
                <IconButton type="submit" sx={{ p: "5px", color: "white" }}>
                  <SearchIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
            )}
            {isUserLoggedIn ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  ml: isMobile ? 0 : 2,
                }}
              >
                <IconButton
                  onClick={handleLogoutIconClick}
                  sx={{ color: "white" }}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Box>
            ) : (
              <Button
                onClick={() => navigate("/login")}
                sx={{
                  color: "black",
                  bgcolor: "white",
                  "&:hover": {
                    bgcolor: "#30673c",
                    color: "white",
                  },
                  flexGrow: 0,
                  ml: isMobile ? 0 : 2,
                }}
                variant="contained"
                
              >
                Log in
              </Button>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
