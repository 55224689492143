// import React from 'react';
// import { Button, Grid, Typography, Card, CardContent, Box } from '@mui/material';

// export default function MatchCard  ({ match })  {
//   return (
//     <Card
//       sx={{
//         border: "1px solid #e0e0e0",
//         borderRadius: "5px",
//         margin: "10px 0",
//         padding: "10px"
//       }}
//     >
//       <CardContent>
//         <Grid container alignItems="center" justifyContent="space-between">
//           {/* Match Information */}
//           <Grid item xs={8}>
//             <Typography variant="body2" color="textSecondary">
//               {match.date} {match.time}
//             </Typography>
//             <Typography variant="subtitle1">
//               {match.team1} VS {match.team2}
//             </Typography>
//           </Grid>

//           {/* Odds */}
//           <Grid item xs={4}>
//             <Grid container justifyContent="space-between">
//               <Button variant="outlined" sx={{ minWidth: "60px" }}>
//                 {match.odds[0]}
//               </Button>
//               <Button variant="outlined" sx={{ minWidth: "60px" }}>
//                 {match.odds[1]}
//               </Button>
//               <Button variant="outlined" sx={{ minWidth: "60px" }}>
//                 {match.odds[2]}
//               </Button>
//               <Button variant="outlined" sx={{ minWidth: "60px" }}>
//                 {match.odds[0]}
//               </Button>
//               <Button variant="outlined" sx={{ minWidth: "60px" }}>
//                 {match.odds[1]}
//               </Button>
//               <Button variant="outlined" sx={{ minWidth: "60px" }}>
//                 {match.odds[2]}
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//       </CardContent>
//     </Card>
//   );
// };

import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../authAxios";

export default function MatchCard({ match }) {
  const navigate = useNavigate();
  const startTime = match?.start_time || "N/A";
  const id = match?.match_id || "N/A";
  const team1 = match?.team1 || "Team 1";
  const team2 = match?.team2 || "Team 2";
  const [odds, setOdds] = useState([]); // Example odds
  const [market_id, setMarketId] = useState();
  const [market_odds, setMarketOdds] = useState();
  const formattedDateTime =
    startTime !== "N/A"
      ? new Date(startTime).toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        })
      : "N/A";

  // Detect mobile view
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const getMarketList = async () => {
      try {
        let res = await axios.get("/t-p/getMarketList", {
          params: {
            match_id: id,
          },
        });
        // console.log("Market List", res.data.dataobj[0].marketId);
        if (res.data.status) {
          // console.log("getMarketList dataobj", res.data.dataobj);
          const oddsMarketId = res.data.dataobj.filter((res) => {
            return res.marketName === "Match Odds";
          });
          // console.log("oddsMarketId", oddsMarketId);
          setMarketId(oddsMarketId[0].marketId);
          getOdds();
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getOdds = async () => {
      // console.log("market_id", market_id);
      try {
        const response = await axios.get("/t-p/getOdds", {
          params: {
            market_id: market_id,
          },
        });

        // console.log("oddsssss response.data.status", response.data.status);
        // console.log(
        //   "oddsssss Array.isArray(response.data.dataobj)",
        //   Array.isArray(response.data.dataobj)
        // );
        // console.log(
        //   "oddsssss response.data.dataobj.length > 0",
        //   response.data.dataobj.length > 0
        // );

        if (
          response.data.status &&
          Array.isArray(response.data.dataobj) &&
          response.data.dataobj.length > 0
        ) {
          const newOdds = response.data.dataobj.map((item) => {
            const oddsArray = [
              item?.runners[0]?.ex?.availableToBack[0]?.price || 0,
              item?.runners[0]?.ex?.availableToBack[1]?.price || 0,
              item?.runners[0]?.ex?.availableToBack[2]?.price || 0,
              item?.runners[0]?.ex?.availableToLay[0]?.price || 0,
              item?.runners[0]?.ex?.availableToLay[1]?.price || 0,
              item?.runners[0]?.ex?.availableToLay[2]?.price || 0,
            ];

            return oddsArray;
          });
          // console.log("new oddsss", newOdds);
          setOdds(newOdds[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getMarketList();
    let intervalIOdds = setInterval(getOdds, 1000);

    return () => clearInterval(intervalIOdds);
  }, [market_id]);

  return (
    <Card
      sx={{
        border: "1px solid #e0e0e0",
        borderRadius: "5px",
        margin: "10px 0",
        background: "white",
      }}
      onClick={() => navigate(`/match/${id}`)}
    >
      <CardContent>
        <Grid container alignItems="center" justifyContent="space-between">
          {/* Match Information */}
          <Grid item xs={12} md={8}>
            <Typography variant="body2" color="textSecondary" fontWeight="700">
              {formattedDateTime}
            </Typography>
            <Typography variant="subtitle1" fontWeight="700">
              {team1} VS {team2}
            </Typography>
          </Grid>

          {/* Odds Section */}
          <Grid item xs={12} md={4} sx={{ mt: isMobile ? 2 : 0 }}>
            {isMobile ? (
              // Mobile View: Same as before, odds below the team names
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "nowrap", // Single row for mobile
                  overflowX: "auto",
                }}
              >
                {odds && odds.length > 0 ? (
                  odds.slice(0, 6).map((odd, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      sx={{
                        flex: 1,
                        margin: "1px",
                        backgroundColor:
                          index % 2 === 0 ? "#80C2F1" : "#FBB7C6", // Even/Odd color
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "700",
                        minWidth: "auto",
                        height: "24px",
                      }}
                    >
                      {odd}
                    </Button>
                  ))
                ) : (
                  <p>No odds available</p>
                )}
              </Box>
            ) : (
              // Desktop View: Display labels and scrollable buttons in a row
              <>
                {/* Scrollable buttons below */}
                <Box
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    whiteSpace: "nowrap", // Prevent buttons from wrapping to a new line
                    justifyContent: "space-between", // Keep buttons spread out
                  }}
                >
                  {odds && odds.length > 0 ? (
                    odds.map((odd, index) => (
                      <Button
                        key={index}
                        variant="outlined"
                        sx={{
                          minWidth: "63px", // Control the width of the buttons
                          height: "30px", // Control height of buttons
                          margin: "4px",
                          backgroundColor:
                            index % 2 === 0 ? "#80C2F1" : "#FBB7C6", // Even/Odd color
                          color: "#000",
                          fontWeight: "700",
                          fontSize: "14px",
                          textAlign: "center",
                        }}
                      >
                        {odd}
                      </Button>
                    ))
                  ) : (
                    <p style={{ textAlign: "center" }}>No odds available</p>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
