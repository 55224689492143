import React, { useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { SlCalender } from "react-icons/sl";
import { MdOutlineClear, MdSearch } from "react-icons/md";
import Table from "../../Componant/Table/Table";
import axios from "../../authAxios";
import { Box, Button, IconButton, TextField, Grid } from "@mui/material";

export default function Statement() {
  const [value, onChange] = useState([new Date(), new Date()]);
  const [data, setData] = useState([]);
  const [apires, setApires] = useState([]);
  const columns = [
    // { name: "ID", selector: (row) => row.id },
    { name: "Date", selector: (row) => row.date },
    { name: "Description", width: 500, selector: (row) => row.description },
    { name: "Cr", selector: (row) => row.credit },
    { name: "Dbt", selector: (row) => row.debit },
    // { name: "Com+", selector: (row) => row.com_plus },
    // { name: "Com-", selector: (row) => row.com_minus },
    { name: "Balance", selector: (row) => row.balance },
  ];
  
  useEffect(() => {
    const getStatements = async () => {
      try {
        const res = await axios.get("/users/getUserStatement", {
          params: {
            user_id: localStorage.getItem("user_id"),
            startDate: value.startDate,
            endDate: value.endDate,
          },
        });
        if (res.data.status) {  
          setApires(res.data.dataobj);
          // setData(getTableReadyData(res.data.dataobj));
          setData(res.data.dataobj);
        }
      } catch (er) {
        console.log(er);
      }
    };
    if (value.startDate && value.endDate) {
      getStatements();
    }
  }, [value]);

  useEffect(() => {
    const getStatements = async () => {
      try {
        const res = await axios.get("/users/getUserStatement", {
          params: {
            user_id: localStorage.getItem("user_id"),
          },
        });
        if (res.data.status) {
          setApires(res.data.dataobj);
          // setData(getTableReadyData(res.data.dataobj));
          setData(res.data.dataobj);
        }
      } catch (er) {
        console.log(er);
      }
    };
      getStatements();
  }, []);

  return (
    <div style={{ maxWidth: "790px", margin: "0 auto", minHeight: "90vh" }}>
      <Box
        sx={{
          maxWidth: "790px",
          margin: "0 auto",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          gap: 2, // Adds space between elements
        }}
      >
        {/* Grid container for date pickers and button */}
        <Grid container spacing={2} alignItems="center">
          {/* First Date Picker */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value.startDate}
              onChange={(e) =>
                onChange({ ...value, startDate: e.target.value })
              }
              fullWidth
            />
          </Grid>

          {/* Second Date Picker */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value.endDate}
              onChange={(e) => onChange({ ...value, endDate: e.target.value })}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Table */}
        <Table columns={columns} data={data} title="Statement" />
      </Box>
    </div>
  );
}
