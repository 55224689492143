import React from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./Sidebars.scss";

const Sidebar = ({ isOpen, onClose }) => {
  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <Box sx={{ width: 250, backgroundColor: "#18AEC5", height: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            backgroundColor: "#18AEC5", // Apply background color here as well
            color: "#FFF",
          }}
        >
          <Typography variant="h6">FLYBET9</Typography>
          <IconButton onClick={onClose} sx={{ color: "#FFF" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ backgroundColor: "#FFF" }} />

        <List sx={{ color: "#FFF" }}>
          {[
            { text: "Home", to: "/" },
            {
              text: "Inplay",
              // to: "/inplay/4"
            },
            {
              text: "Sportsbook",
              // to: "/sportsbook"
            },
            {
              text: "My Statement",
              to: "/statement"
            },
            { text: "Cricket", to: "/live/4" },
            { text: "Soccer", to: "/live/1" },
            { text: "Tennis", to: "/live/2" },
            {
              text: "Change Password",
              to: "/change-password",
            },
          ].map((item, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={item.to}
              onClick={onClose}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
